<template>
  <div>
    <v-stepper v-model="e1" style="background-color: #212429">
      <v-stepper-header class="elevation-0">
        <template v-for="n in steps">
          <v-stepper-step
            :key="`${n.step}-step`"
            :complete="e1 > n.step"
            :step="n.step"
          >
            {{ n.des }}
          </v-stepper-step>

          <v-divider v-if="n.step < steps.length" :key="n.step"></v-divider>
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <component :is="component" :editFc="editFc" @stepNo="getStep($event)" />
        </v-stepper-content>
        <v-stepper-content step="2" class="pa-3">
          <v-row class="ma-0">
            <v-col
              cols="12"
              md="4"
              class="pa-3"
            >
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-food"
                prefix=" | "
                clearable
                label="Search Restaurants"
                single-line
                :disabled="loading"
                hide-details
                @keyup.enter="searchFilter()"
              ></v-text-field>
            </v-col>
          </v-row>
          <div v-if="loading" class="pt-8 text-center">
            <v-progress-circular :size="80" color="primary" indeterminate />
            <p class="pt-8">Loading...</p>
          </div>
          <div v-else>
            <v-item-group v-model="selectedRes">
              <v-row class="ma-0">
                <v-col
                  cols="12"
                  md="4"
                  class="pa-3"
                  v-for="tenant in tenants"
                  :key="tenant.id"
                >
                  <v-item v-slot="{ active, toggle }">
                    <v-card
                      :style="
                        active ? 'border: 1px solid #66D1A5 !important' : ''
                      "
                      @click="toggle"
                      class="card"
                      style="border-radius: 12px !important"
                    >
                      <v-row
                        class="ma-0"
                        align="center"
                        justify="space-between"
                      >
                        <v-card-title class="text-h6 pb-0">{{
                          tenant.name
                        }}</v-card-title>
                        <v-btn class="mt-3 mr-2" icon dark>
                          <v-icon v-if="active" class="primary--text">
                            mdi-radiobox-marked
                          </v-icon>
                          <v-icon v-else> mdi-radiobox-blank </v-icon>
                        </v-btn>
                      </v-row>
                      <v-card-text class="pt-0">
                        <div class="text-body-1">
                          {{ tenant.subscription ? tenant.subscription.packages.length > 0 ? tenant.subscription.packages[0].name : '' : '' }}
                        </div>
                        <!-- <v-chip-group class="mt-4">
                          <div v-for="i in 2" :key="i">
                            <v-chip small>Branch</v-chip>
                            <v-chip small v-if="i>1">6+</v-chip>
                          </div>
                        </v-chip-group> -->
                      </v-card-text>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-item-group>
            <v-btn
              fixed
              bottom
              right
              :disabled="selectedRes == null || loading"
              :loading="loading"
              color="primary"
              class="mr-2"
              @click="onSecond"
            >
              Continue
            </v-btn>
          </div>
        </v-stepper-content>
        <v-stepper-content step="3" class="pa-3">
          <div v-if="loading" class="pt-8 text-center">
            <v-progress-circular :size="80" color="primary" indeterminate />
            <p class="pt-8">Loading...</p>
          </div>
          <div v-else-if="branches.length == 0">
            <div class="text-h4 text-center mt-10">
              There is no Branch Found
            </div>
            <v-btn class="float-right mr-6" color="primary" @click="e1 = 2"
              >Go Back</v-btn
            >
          </div>
          <div class="v-else">
            <v-item-group v-model="selectedBranch">
              <v-row class="ma-0">
                <v-col
                  cols="12"
                  md="4"
                  class="pa-3"
                  v-for="branch in branches"
                  :key="branch.id"
                >
                  <v-item v-slot="{ active, toggle }">
                    <v-card
                      :style="
                        active ? 'border: 1px solid #66D1A5 !important' : ''
                      "
                      @click="toggle"
                      class="card"
                      style="border-radius: 12px !important"
                    >
                      <v-row
                        class="ma-0"
                        align="center"
                        justify="space-between"
                      >
                        <v-card-title class="text-h6 pb-0">{{
                          branch.name
                        }}</v-card-title>
                        <v-btn class="mt-3 mr-2" icon dark>
                          <v-icon v-if="active" class="primary--text">
                            mdi-radiobox-marked
                          </v-icon>
                          <v-icon v-else> mdi-radiobox-blank </v-icon>
                        </v-btn>
                      </v-row>
                      <v-card-text class="pt-0">
                        <div class="text-body-1">
                          {{ branch.address[0].area }}
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-item-group>
            <v-card-actions v-if="branches.length > 0">
              <v-spacer></v-spacer>
              <v-btn
                outlined
                text
                color="primary"
                @click="
                  e1 = 2;
                  selectedBranch = null;
                "
                >Go Back</v-btn
              >
              <v-btn
                :disabled="selectedBranch == null"
                :loading="loading"
                color="primary"
                @click="onThird"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </div>
        </v-stepper-content>
        <v-stepper-content step="4">
          <component
            :is="component"
            :editBranchData="editBranchData"
            :step4edit="step4edit"
            :step2Status="step2Status"
            @back3="backTo3"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import store from "../../store";
import { eventBus } from "../../main";
export default {
  props: ["step2Status", "editFc", "editBranchData", "step4edit"],
  data() {
    return {
      e1: 1,
      steps: [
        { step: 1, des: "Add Foodcourt" },
        { step: 2, des: "Select Restaurant" },
        { step: 3, des: "Attach Branch" },
        { step: 4, des: "SLAB Information" },
      ],
      component: () => import("../Foodcourt/FoodcourtForm"),
      tenants: [],
      branches: [],
      selectedRes: null,
      selectedBranch: null,
      loading: false,
      search: '',
    };
  },

  // watch: {
  //   selectedRes(val) {
  //     console.log("selectedRes", val)
  //   },
  //   selectedBranch(val) {
  //     console.log("selectedBranch", val)
  //   },
  // },

  created() {
    // console.log("step2Status", this.step2Status, 'step4Edit', this.step4edit)
    if (this.step2Status) {
      this.getStep(2);
    }
    if(this.step4edit) {
      this.e1 = 4
      this.component = () => import("../Foodcourt/SlabForm")
      // console.log('step edit ', this.step4edit, this.e1)
    }
  },

  methods: {
    searchFilter () {
      this.loading = true;
      const payload = {
        search: this.search,
        page: 1
      }
      store.dispatch('getAllTenantsFilter', payload)
        .then((res) => {
          // console.log(res.data.data)
          this.tenants = res.data.data.tenants.data
          this.loading = false
        }).catch(err => {
            console.log(err)
            this.loading = false
            eventBus.$emit('snackbar', {
              snackbarColor: "#FF5252",
              snackbarText: "Something Went Wrong!",
            })
          })
    },
    backTo3() {
      this.e1 = 3;
    },
    getStep(n) {
      // console.log(n)
      this.e1 = n;
      if (n == 2) {
        this.loading = true;
        store
          .dispatch("getAllTenants")
          .then((res) => {
            this.tenants = res.data.data.tenants.data;
            // console.log(this.tenants)
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            eventBus.$emit("snackbar", {
              snackbarColor: "#FF5252",
              snackbarText: "Something Went Wrong!",
            });
          });
      }
    },
    onSecond() {
      this.loading = true;
      store.commit("setTenantId", this.tenants[this.selectedRes].id);
      store
        .dispatch("getBranch", this.tenants[this.selectedRes].slug)
        .then((res) => {
          this.e1 = 3;
          this.branches = res.data.data.branches.branches;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    onThird() {
      store.commit("setBranchId", this.branches[this.selectedBranch].id);
      this.e1 = 4;
      this.component = () => import("../Foodcourt/SlabForm");
    },
  },
};
</script>
